import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import AboutUsContent from '../components/AboutUs/AboutUsContent'
// import OurHistory from '../components/AboutUs/OurHistory'
import WhyChooseUs from '../components/AboutUs/WhyChooseUs'
// import HowItWork from '../components/AboutUs/HowItWork'
// import TeamMembers from "../components/DataScienceAndMLCompany/TeamMembers"

// import Testimonials from '../components/AboutUs/Testimonials'
// import Partner from '../components/AboutUs/Partner'
 
const AboutUsTemplate = (props) => {
    let data = props.data.wpgraphql;

    console.log(props)
    
    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://w3vitals.com${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://w3vitals.com${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <Layout metaData={constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)}>
            <Navbar header={props.pageContext.headerMenu}  lang={props.pageContext.language} />
            <PageBanner
                pageTitle="Web & Digital Marketing Agency" 
                homePageText={props.pageContext.language ==="EN" ? "Home" : (props.pageContext.language ==="FR" ? "Accueil" : "Αρχική")}
                homePageUrl={props.pageContext.language ==="EN" ? "/en/" : (props.pageContext.language ==="FR" ? "/fr/" : "/")} 
                activePageText="Web & Digital Marketing Agency"
            />
            <AboutUsContent 
                data={ data.page.aboutPageAcf.aboutUs }
                static={ props.data.circleShape1}
            />
            {/* <OurHistory /> */}
            <WhyChooseUs 
                data={ data.page.aboutPageAcf.whyUs }
            />
            {/* <HowItWork /> */}
            {/* <TeamMembers 
                ourTeamHeader= {data.page.aboutPageAcf.teamMember.title}
                ourTeamSubHeader= {data.page.aboutPageAcf.teamMember.subtitle}
                ourTeamParagraph= {data.page.aboutPageAcf.teamMember.content}
                teamMembers= {data.teamMembers.edges}
                
            /> */}
            {/* <Testimonials /> */}
            {/* <Partner /> */}
            <Footer footer={props.pageContext.footerMenu} extra={data.footerTemplate.footerTemplateAcf}/>
        </Layout>
    );
}

export default AboutUsTemplate;

export const pageQuery = graphql`
    query GET_ABOUT_US($id: ID! , $language: WPGraphQL_LanguageCodeFilterEnum, $footerTemplateUri: ID! ) {
        wpgraphql {
            page(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                breadcrumbAcf{
                    breadcrumbName
                }
			    aboutPageAcf {
                    aboutUs {
                        title
                        subtitle
                        content
                        cta
                        ctaUrl
                        image {
                            altText
                            sourceUrl
                            imageFile{
                                childImageSharp {
                                    fluid(maxWidth: 930, quality:100){
                                        base64
                                        aspectRatio
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                        sizes
                                    }
                                }
                            }
                        }
                        imageMobile {
                            altText
                            sourceUrl
                            imageFile{
                                childImageSharp {
                                    fluid(maxWidth: 500, quality:90){
                                        base64
                                        aspectRatio
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                        sizes
                                    }
                                }
                            }
                        }
                        subSection {
                            title
                            content
                        }
                    }
                    whyUs{
                        title
                        subtitle
                        content
                        list{
                            title
                            content
                        }
                        image {
                            altText
                            sourceUrl
                            imageFile{
                                childImageSharp {
                                    fluid(maxWidth: 585, quality:100){
                                        base64
                                        aspectRatio
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                        sizes
                                    }
                                }
                            }
                        }
                        imageMobile {
                            altText
                            sourceUrl
                            imageFile{
                                childImageSharp {
                                    fluid(maxWidth: 585){
                                        base64
                                        aspectRatio
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                        sizes
                                    }
                                }
                            }
                        }
                    }
                    teamMember {
                        title
                        subtitle
                        content
                    }
                }
            }
            teamMembers(first: 200, where: {language: $language, orderby: {field: MENU_ORDER, order: ASC}}) {
                ...OurTeamFragment
            }
            footerTemplate: template(id: $footerTemplateUri, idType: SLUG) {
                footerTemplateAcf{
                    addressTitle
                    addressContent
                    content
                }
            }
        }
        circleShape1: file(relativePath: { eq: "shape/circle-shape1.png" }) {
            childImageSharp {
                fixed(width: 350 , height: 386){
                    base64
                    width
                    height
                    src
                    srcSet
                }
            }
        }
    }
`