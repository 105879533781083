import React from 'react'
import { Link } from 'gatsby'
import Img from "gatsby-image"

const AboutUsContent = (props) => {
    const data = props.data
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image" style={{minHeight: "232px"}}>
                            <div >
                                <picture>
                                    <source type="image/webp" srcset={ data.image.imageFile.childImageSharp.fluid.srcSetWebp } media="(min-width: 501px)" importance="high" />
                                    <source type="image/webp" srcset={  data.imageMobile.imageFile.childImageSharp.fluid.srcSetWebp} media="(max-width: 500px)" importance="high" />
                                    <source type="image/jpg" srcset={ data.image.imageFile.childImageSharp.fluid.srcSet} media="(min-width: 501px)"/>
                                    <source type="image/jpg" srcset={  data.imageMobile.imageFile.childImageSharp.fluid.srcSet} media="(max-width: 500px)"/>

                                    <img
                                        alt={data.imageMobile.altText}
                                        importance="high"
                                        style={{ objectFit: "cover", width: "100%", height: "100%" }}
                                        importance="high"
                                        className="bg-position"
                                    />
                                </picture>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    {data.subtitle}
                                </span>
                                <h2>{data.title}</h2>
                                <p style={{paddingTop: "1rem"}}>{data.content}</p>

                                {/* <Link to={`/${data.ctaUrl}/`} className="default-btn">
                                    <i className="flaticon-right"></i>{data.cta}<span></span>
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row">
                        {data.subSection && data.subSection.map((sl, i) => (
                            <div className="col-lg-4 col-md-6 col-sm-6" key={`ab-sl-${i}`}>
                                <div className="about-text">
                                    <h3>{sl.title}</h3>
                                    <div className="about-sub-section" dangerouslySetInnerHTML={{ __html: sl.content }}></div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <Img fixed={props.static.childImageSharp.fixed} alt="banner" />
            </div>
        </section>
    )
}

export default AboutUsContent;