import React from 'react'
// import W3Gif from '../../assets/images/gifs/w3-gif-3.gif'
import Img from "gatsby-image"


const WhyChooseUs = (props) => {
    const data = props.data;
    
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                {/* <img src={W3Gif} alt="banner" style={{width: "46px"}} />  */}
                                {data.subtitle}
                            </span>
                            <h2>{data.title}</h2>
                            <p>{data.content}</p>
                            <div className="inner-box">
                                { data.list && data.list.map((cl, i) => (
                                    <div className="single-item" key={`ab-cl-${i}`}>
                                        <div className="count-box">
                                            {i + 1}
                                        </div>
                                        <h3>{cl.title}</h3>
                                        <p>{cl.content}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image d-none d-md-block" style={{maxWidth: "540px",position:"relative", top:"70px"}}>
                            <Img fluid={data.image.imageFile.childImageSharp.fluid} alt={data.image.altText} loading="lazy" />
                        </div>
                        <div className="how-its-work-image d-xs-block d-sm-block d-md-none">
                            <Img fluid={data.imageMobile.imageFile.childImageSharp.fluid} alt={data.imageMobile.altText} loading="lazy" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs